.ouTimepicker {
	position: relative;
	color: #ffffff;
	user-select: none;
    display: block;
}

.ouTimepicker input:focus {
	border-color: #d9d9d9;
}

.ouTimepicker input:disabled,
.ouTimepicker input[readonly] {
	background: #ffffff;
	user-select: none;
}

.ouTimepicker.open input {
	color: #292b2c !important;
	background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

.ouTimepicker__popover {
	display: block;
	position: absolute;
	z-index: 999;
	width: 18rem;

    padding: 1rem;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}

.ouTimepicker__popover.open-down {
	top: 2.5rem;
}

.ouTimepicker__popover.open-up {
	bottom: 2.5rem;
}

.ouTimepicker__popover.float-left {
	left: 0;
}

.ouTimepicker__popover.float-right {
	right: 0;
}

/*.ouTimepicker__popover::before {
	position: absolute;
	content: '';
	z-index: 999;
    top: -11px;
    left: 16px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #ffffff;
}

.ouTimepicker__popover::after {
	position: absolute;
	content: '';
	z-index: 998;
    top: -12px;
    left: 16px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #cccccc;
}*/

.ouTimepicker .ouTimepicker__sliders {
	padding: 0 1rem;
}

.ouTimepicker .ouTimepicker__text {
	position: relative;
	left: -1rem;

	font-size: 1rem;
	/*color: #e80c52;*/
	color: #3c3f40;

	margin-top: 7px;
	margin-bottom: 10px;

	cursor: default;
}

.ouTimepicker__slider {
	position: relative;
	display: inline-block;
	width: 100%;
	cursor: pointer
}

.ouTimepicker__buttons {
    text-align: center;
    padding-top: 1rem;
}

.ouTimepicker__button {
    border: 0;
    margin: 0 0.5rem;
    height: 2rem;
    width: 5rem;
    text-align: center;

	transition: all 0.2s ease-in-out;
}

.ouTimepicker__button.disabled,
.ouTimepicker__button:disabled {
    cursor: not-allowed;
    opacity: .65;
}

.ouTimepicker__button.save {
	color: #ffffff;
    background-color: #5cb85c;
    border: 1px solid #666;
}

.ouTimepicker__button.cancel {
	color: #999;
    background-color: #fff;
    border: 1px solid #666;
}

.ouTimepicker__button.save:hover {
    color: #666;
    background-color: #449d44;
    border: 1px solid #666;
}

.ouTimepicker__button.cancel:hover {
	color: #666;
	background-color: #e5e5e5;
    border: 1px solid #666;
}

.ouTimepicker__button.save.disabled,
.ouTimepicker__button.save:disabled {
    background-color: #5cb85c;
}

.ouTimepicker__button.cancel,
.ouTimepicker__button.cancel:disabled {
    background-color: #fff;
}
