/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                             Text/Background                             //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.ouText {
	color: #e80c52;
}

.smileys .ouText {
	color: #d1060c;
}

.ouText--noselect {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

.ouText--left--small {
	text-align: left;
}
.ouText--right--small {
	text-align: left;
}

@media (min-width: 576px) {
	.ouText--left--small {
		text-align: right !important;
	}
	.ouText--right--small {
		text-align: right !important;
	}
}

.ouBackground {
	background-color: #e80c52;
}

.smileys .ouBackground {
	background-color: #d1060c;
}

.ouText--brown {
	color: #a86415;
}

.ouBackground--brown {
	background-color: #a86415;
}

.ouText--light {
	color: #f0dcc0;
}

.ouBackground--light {
	background-color: #f0dcc0;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Buttons                                 //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.ouButton {
	display: inline-block;
	font-weight: normal;
	line-height: 1.25;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 0.5rem 1rem;
	border: none;
	font-size: 1rem;
	transition: all 0.2s ease-in-out;
}

.ouButton--small {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}

.ouButton {
	color: #ffffff;
	background-color: #e80c52;
}

.smileys .ouButton {
	background-color: #d1060c;
}

.ouButton--brown {
	color: #ffffff;
	background-color: #a86415;
}

.smileys .ouButton--brown {
	background-color: #6c4233;
}

.ouButton--link {
	color: #58666e;
	background-color: #ffffff;
}

.smileys .ouButton--link {
	color: #9e0409;
}

.ouButton:hover, .ouButton:focus {
	color: #ffffff;
	background-color: #980735;
	text-decoration: none;
}

.smileys .ouButton:hover,
.smileys .ouButton:focus {
	background-color: #9e0409;
}

.ouButton--brown:hover, .ouButton--brown:focus {
	color: #ffffff;
	background-color: #794407;
}

.smileys .ouButton--brown:hover,
.smileys .ouButton--brown:focus {
	background-color: #583529;
}

.ouButton--link:hover, .ouButton--link:focus {
	color: #583529;
	background-color: #ffffff;
}

.smileys .ouButton--link:hover,
.smileys .ouButton--link:focus {
	color: #d1060c;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                Input Group                              //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.ouInputGroup--placeholder {
	height: 38px;
	margin-bottom: 1.5rem;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                          Login / Forgot Page                            //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.pageAuth {
	display: flex;
    min-height: 100vh;
    align-items: center !important;
	background-color: rgba(168, 100, 21, 0.20);
}

.smileys .pageAuth {
	background-color: rgba(108, 66, 51, 0.25);
}

.pageAuth::after,
.pageAuth.pageForgot::after {
	content: '';
	z-index: -1;
	opacity: 0.25;
	position: absolute;
	width: 100%;
	height: 100%;
	background: -moz-radial-gradient(center, ellipse cover, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 27%, rgba(224,210,195,0.1) 43%, rgba(224,210,195,0.62) 72%, rgba(224,210,195,1) 93%), url('../img/ou-bg-pattern2.png') repeat right top;
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(224,210,195,0)), color-stop(0%, rgba(224,210,195,0)), color-stop(27%, rgba(224,210,195,0)), color-stop(43%, rgba(224,210,195,0.1)), color-stop(72%, rgba(224,210,195,0.62)), color-stop(93%, rgba(224,210,195,1))), url('../img/ou-bg-pattern2.png') repeat right top;
	background:-webkit-radial-gradient(center, ellipse cover, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 27%, rgba(224,210,195,0.1) 43%, rgba(224,210,195,0.62) 72%, rgba(224,210,195,1) 93%), url('../img/ou-bg-pattern2.png') repeat right top;
	background: -o-radial-gradient(center, ellipse cover, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 27%, rgba(224,210,195,0.1) 43%, rgba(224,210,195,0.62) 72%, rgba(224,210,195,1) 93%), url('../img/ou-bg-pattern2.png') repeat right top;
	background: -ms-radial-gradient(center, ellipse cover, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 27%, rgba(224,210,195,0.1) 43%, rgba(224,210,195,0.62) 72%, rgba(224,210,195,1) 93%), url('../img/ou-bg-pattern2.png') repeat right top;
	background:radial-gradient(ellipse at center, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 0%, rgba(224,210,195,0) 27%, rgba(224,210,195,0.1) 43%, rgba(224,210,195,0.62) 72%, rgba(224,210,195,1) 93%), url('../img/ou-bg-pattern2.png') repeat right top;
}

.smileys .pageAuth::after,
.smileys .pageAuth.pageForgot::after {
	background: -moz-radial-gradient(center, ellipse cover, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 27%, rgba(108,66,51,0.1) 43%, rgba(108,66,51,0.62) 72%, rgba(108,66,51,1) 93%), url('../img/___/smileys/bg-pattern.png') repeat right top;
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(108,66,51,0)), color-stop(0%, rgba(108,66,51,0)), color-stop(27%, rgba(108,66,51,0)), color-stop(43%, rgba(108,66,51,0.1)), color-stop(72%, rgba(108,66,51,0.62)), color-stop(93%, rgba(108,66,51,1))), url('../img/___/smileys/bg-pattern.png') repeat right top;
	background:-webkit-radial-gradient(center, ellipse cover, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 27%, rgba(108,66,51,0.1) 43%, rgba(108,66,51,0.62) 72%, rgba(108,66,51,1) 93%), url('../img/___/smileys/bg-pattern.png') repeat right top;
	background: -o-radial-gradient(center, ellipse cover, rgba(1108,66,51,0) 0%, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 27%, rgba(108,66,51,0.1) 43%, rgba(108,66,51,0.62) 72%, rgba(108,66,51,1) 93%), url('../img/___/smileys/bg-pattern.png') repeat right top;
	background: -ms-radial-gradient(center, ellipse cover, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 27%, rgba(108,66,51,0.1) 43%, rgba(108,66,51,0.62) 72%, rgba(108,66,51,1) 93%), url('../img/___/smileys/bg-pattern.png') repeat right top;
	background:radial-gradient(ellipse at center, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 0%, rgba(108,66,51,0) 27%, rgba(108,66,51,0.1) 43%, rgba(108,66,51,0.62) 72%, rgba(108,66,51,1) 93%), url('../img/___/smileys/bg-pattern.png') repeat right top;
	opacity: 0.1;
}

.ouAuth--wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.ouAuth--grid {
	margin-left: auto;
	margin-right: auto;
	max-width: 25rem;
	width: 90%;
}

.ouAuth--card {
	padding: 2rem;
	background-color: rgba(255, 255, 255, 0.70)
}

.ouAuth--icons {
	display: inline-block;
	fill: #606468;
	font-size: 1rem;
	height: 1em;
	vertical-align: middle;
	width: 1em;
}

.ouAuth--form {
	margin: calc(0.875rem * -1);
}

.ouAuth--logo {
	user-select: none;
	display: flex;
	margin: 0.875rem auto;
	width: 70%;
}

.smileys .ouAuth--logo {
	width: 50%;
}

.ouAuth--progress {
	height: 0.25rem;
	border-radius: .1rem;
	user-select: none;
	display: flex;
	margin: 0 0.875rem;
	background-color: rgba(175, 175, 175, 0.5);
}

.ouAuth--progress--bar {
	transition: width 0.3s;
}

.ouAuth--form h1,
.ouAuth--form h2,
.ouAuth--form h3,
.ouAuth--form h4,
.ouAuth--form h5,
.ouAuth--form h6,
.ouAuth--form p {
    display: flex;
	margin: 0 0.875rem 0.5rem;
    text-align: center !important;
    color: #363b41;
}

.ouAuth--form input,
.ouAuth--submit {
	background-image: none;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
	transition: background-color 0.3s;
	width: 100%;
}

.ouAuth--form--field {
	display: flex;
	margin: 0.875rem;
}

.ouAuth--form--input {
	flex: 1;
}

.ouAuth--form {
	color: #eeeeee;
}

.ouAuth--form label,
.ouAuth--form input,
.ouAuth--submit {
	border-radius: .1rem;
	padding: 1rem;
}

.ouAuth--form label {
	background-color: #363b41;
	border-bottom-right-radius: 0 !important;
	border-top-right-radius: 0 !important;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
    text-align: center;
	min-width: 3.5rem;
}

.ouAuth--form label > .ouAuth--icon {
    width: 100%;
}

.ouAuth--form--input {
	color: #363b41;
	background-color: #ffffff;
	border-bottom-left-radius: 0 !important;
	border-top-left-radius: 0 !important;
}

.ouAuth--form--input:focus,
.ouAuth--form--input:hover {
  	background-color: #dedede;
}

.ouAuth--form--input[disabled] {
	user-select: none;
    cursor: default;
    color: #a7a7a7 !important;
    background-color: #616161 !important;
}

.ouAuth--form--pass {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    transition: background-color .3s;
}

.ouAuth--form--pass:not(.disabled):hover,
.ouAuth--form--pass:not(.disabled):focus {
    background-color: #dedede !important;
}

.ouAuth--form--pass.disabled,
.ouAuth--form--pass.disabled * {
	user-select: none;
    cursor: default;
    color: #a7a7a7 !important;
    background-color: #616161 !important;
}

.ouAuth--form--pass--show {
    color: #848484;
    padding: 1rem 0.75rem;
    border-radius: 0 .1rem .1rem 0;
    cursor: pointer;
	transition: color .3s;
}

.ouAuth--form--pass--show:hover,
.ouAuth--form--pass--show:focus {
    color: #e80c52;
}

.smileys .ouAuth--form--pass--show:hover,
.smileys .ouAuth--form--pass--show:focus {
    color: #d1060c;
}

.ouAuth--form--pass
> .ouAuth--form--input {
    background: none !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.ouAuth--submit,
.ouAuth--submit * {
	user-select: none;
}

.ouAuth--submit {
	cursor: pointer;
	color: #eeeeee;
	background-color: #e80c52;
	text-transform: uppercase;
}

.smileys .ouAuth--submit {
	background-color: #d1060c;
}

.ouAuth--submit[disabled] {
    cursor: not-allowed;
    background-color: #848484 !important;
}

.ouAuth--submit.success {
	color: #eeeeee;
	background-color: #5cb85c !important;
}

.ouAuth--submit:focus,
.ouAuth--submit:hover {
	background-color: #980735;
}

.smileys .ouAuth--submit:focus,
.smileys .ouAuth--submit:hover {
	background-color: #9e0409;
}

.ouAuth--forgot,
.ouAuth--forgot > button {
	user-select: none;
	margin: 1.5rem 0;
	padding: 0;
	background: none;
	border: none;
	color: #777777;
	text-align: center;
	cursor: pointer !important;
}

.smileys .ouAuth--forgot,
.smileys .ouAuth--forgot > button {
	color: #000000;
}

.ouAuth--headline {
	display: block !important;
	font-family: "Open Sans",sans-serif;
    font-weight: lighter;
	text-transform: uppercase;
	color: #a8630f !important;
	font-size: 1.5rem;
	user-select: none;
	cursor: default;
}

.smileys .ouAuth--headline {
	color: #6c4233 !important;
}

.ouAuth--pass-verify {
    margin: 0;
    padding: 0;
    display: flex;
    margin: 0.875rem;
    flex-wrap: wrap;
}

.ouAuth--pass-verify * {
    user-select: none;
	cursor: default;
}

.ouAuth--pass-verify--entry {
    float: left;
	-ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
	padding: 0 0.25rem;
}

.ouAuth--pass-verify--entry:first-child {
	padding: 0 .25rem 0 0;
}

.ouAuth--pass-verify--entry:last-child {
	padding: 0 0 0 .25rem;
}

.ouAuth--pass-verify--item {
	padding: .5rem;
	background-color: #d9534f;
}

.ouAuth--pass-verify--item.valid {
	padding: .5rem;
	background-color: #5cb85c;
}

.ouAuth--pass--headline {
    color: #ffffff;
    font-family: "Open Sans",sans-serif;
    font-weight: lighter;
	text-align: center;
    font-size: 1.5rem;
    display: block;
}

.ouAuth--pass--info {
    color: #ffffff;
	text-align: center;
    display: block;
}

@media (max-width: 879px) {
	.ouAuth--card {
		padding: 1rem;
	}

	.ouAuth--logo {
		user-select: none;
		display: flex;
		width: 60%;
		margin: 1.5em auto 0.75rem;
	}

	.ouAuth--headline {
		font-size: 1.25rem;
	}
}

.form.editable label {
	user-select: none !important;
    color: rgba(0, 0, 0, 0.75) !important;
}

.form.editable input[disabled] {
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: none;
	cursor: default;
}

.form.editable .static label:not([disabled]) {
    color: rgba(0, 0, 0, 0.25);
}

.form.editable .static input:not([disabled]) {
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: none;
	color: rgba(0, 0, 0, 0.25) !important
}

/* Remove Focus Outline */
.btn {
    outline: none !important;
    box-shadow: none !important;
}

.ouTable__wrapper {
	position: relative;
}

.ouTable__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 99;
}

.ouTable__overlay--icon {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	margin-top: -10px;
	margin-left: -10px;
}

.ouTable__wrapper.loading > .ouTable__overlay {
	display: block;
}

span.id::after {
    content: ' | ';
    color: #bfbfbf;
}
