.ouStateButton {
	display: inline-block;
	font-weight: normal;
	line-height: 1.25;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 0;
	transition: all 0.2s ease-in-out;
}

.ouStateButton.small {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
    line-height: 1.5;
}

.ouStateButton:focus,
.ouStateButton:hover {
    text-decoration: none;
	box-shadow: none;
	outline: none;
}

.ouStateButton.disabled,
.ouStateButton:disabled {
    cursor: not-allowed;
    opacity: .65;
}

/* Styles */
.ouStateButton.primary {
    color: #ffffff;
    background-color: #e80c52;
    border-color: #666;
}

.smileys .ouStateButton.primary {
    background-color: #d1060c;
}

.ouStateButton.primary:hover {
    color: #ffffff;
    background-color: #d00a49;
}

.smileys .ouStateButton.primary:hover {
    background-color: #9e0409;
}

/**/
.ouStateButton.success {
    color: #ffffff;
    background-color: #5cb85c;
    border-color: #666;
}

.ouStateButton.success:hover {
    color: #ffffff;
    background-color: #449d44;
}

/**/
.ouStateButton.info {
    color: #999;
    background-color: #fff;
    border-color: #666;
}

.ouStateButton.info:hover {
    color: #ffffff;
    background-color: #e5e5e5;
}

/**/
.ouStateButton.warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #666;
}

.ouStateButton.warning:hover {
    color: #fff;
    background-color: #ec971f;
}

/**/
.ouStateButton.danger {
    color: #ffffff;
    background-color: #a8630f;
    border-color: #666;
}

.smileys .ouStateButton.danger {
    background-color: #6c4233;
}

.ouStateButton.danger:hover {
    color: #ffffff;
    background-color: #97590d;
}

.smileys .ouStateButton.danger:hover {
    background-color: #583529;
}
