.ouCheckbox {
    position: relative;
}

.ouCheckbox.form-control {
	padding: 0;
	border: none;
}

/* Label Styles */
.ouCheckbox__label {
    display: flex;
    align-items: center;
}

.ouCheckbox__text {
	flex: 1;
    padding-left: 0.5rem;
	user-select: none;
}

.ouCheckbox__label.muted .ouCheckbox__text {
    color: #b0bec5 !important;
}

/* Checkbox Styles */
.ouCheckbox.checkbox .ouCheckbox__box {
    position: relative;
	height: 2rem;
    width: 2rem;
	background-color: #ffffff;
    display: inline-block;
	border: 1px solid #e80c52;
	cursor: pointer;
}

.ouCheckbox.checkbox.rounded .ouCheckbox__box {
    border-radius: 1rem;
}

.ouCheckbox.checkbox.form-control .ouCheckbox__box {
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.ouCheckbox.checkbox .ouCheckbox__box::before {
    content: '';
    position: absolute;
    background-color: #e80c52;
    z-index: 1;
    transition: all ease-in-out 0.05s;
}

.ouCheckbox.checkbox.rounded .ouCheckbox__box::before {
    border-radius: 1rem;
}

.ouCheckbox.checkbox.form-control .ouCheckbox__box::before {
	background-color: #495057;
}

.ouCheckbox.checkbox input[type="checkbox"]:not(:checked)
+ label .ouCheckbox__box::before {
	top: 1rem;
	left: 1rem;
	width: 0;
    height: 0;
}

.ouCheckbox.checkbox input[type="checkbox"]:checked
+ label .ouCheckbox__box::before {
	top: 0.2125rem;
	left: 0.2125rem;
	width: 1.5rem;
    height: 1.5rem;
}

/* Switch Styles */
.ouCheckbox.switch .ouCheckbox__box {
    position: relative;
	height: 2rem;
    width: 4rem;
	background-color: #ffffff;
	border: 1px solid #e80c52;
    display: inline-block;
	cursor: pointer;
}

.ouCheckbox.switch.rounded .ouCheckbox__box {
    border-radius: 1rem;
}

.ouCheckbox.switch.form-control .ouCheckbox__box {
    background-color: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.ouCheckbox.switch .ouCheckbox__box::before {
    content: '';
    position: absolute;
    top: 0.2125rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #e80c52;
    z-index: 1;
    left: 0.25rem;
	animation-duration: .15s;
    animation-timing-function: ease-in-out;
}

.ouCheckbox.switch.rounded .ouCheckbox__box::before {
    border-radius: 1rem;
}

.ouCheckbox.switch.form-control .ouCheckbox__box::before {
	background-color: #495057;
}

.ouCheckbox.switch input[type="checkbox"]:not(:checked)
+ label .ouCheckbox__box::before {
	left: .25rem;
	width: 1.5rem;
	animation-name: switchEffectOff;
}

.ouCheckbox.switch input[type="checkbox"]:checked
+ label .ouCheckbox__box::before {
	left: calc(100% - 1.5rem - .25rem);
	width: 1.5rem;
    animation-name: switchEffectOn;
}

.ouCheckbox.switch .ouCheckbox__box::after {
    content: '';
    position: absolute;
    top: 0.2125rem;
    width: calc(100% - 0.5rem);
    height: 1.5rem;
    left: 0.25rem;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-transition: all ease-in-out 0.15s;
    -o-transition: all ease-in-out 0.15s;
    transition: all ease-in-out 0.15s;
}

.ouCheckbox.switch.rounded .ouCheckbox__box::after {
    border-radius: 1rem;
}

@keyframes switchEffectOn {
    0% {
        left: .25rem;
        width: 1.5rem;
    }
    50% {
        left: calc(100% - 3rem - .25rem);
        width: 3rem;
    }
    100% {
        left: calc(100% - 1.5rem - .25rem);
        width: 1.5rem;
    }
}

@keyframes switchEffectOff {
    0% {
        left: calc(100% - 1.5rem - .25rem);
        width: 1.5rem;
    }
    50% {
        left: calc(100% - 3rem - .25rem);
        width: 3rem;
    }
    100% {
        left: .25rem;
        width: 1.5rem;
    }
}

/* Input */
.ouCheckbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}
