.ouCurrencyInput {
    position: relative;
}
.ouCurrencyInput__display {
	font-family: sans-serif;
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #ffffff;
    background-image: none;
    background-clip: padding-box;
	border-radius: 0;
	border: 1px solid rgba(0,0,0,.15);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.ouCurrencyInput__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
}
