.ouSlider {
	position: relative;
	display: inline-block;

	background-color: #dddddd;
	border-radius: 0.2rem;
}

.ouSlider__value {
	position: absolute;
	background-color: #e80c52;
	border-radius: 0.2rem;
}

.smileys .ouSlider__value {
	background-color: #d1060c;
}

.ouSlider__handle {
	position: absolute;
	width: 1.5rem;
	height: 1.5rem;
}

.ouSlider__handle:after {
  position: relative;
  display: block;
  content: '';
}

.ouSlider.x .ouSlider__handle:after,
.ouSlider.y .ouSlider__handle:after {
	width: 1.5rem;
	height: 1.5rem;

	background-color: #ffffff;
	border: 0.2rem solid #e80c52;

	border-radius: 50%;
}

.smileys .ouSlider.x .ouSlider__handle:after,
.smileys .ouSlider.y .ouSlider__handle:after {
	border-color: #d1060c;

}

.ouSlider.x {
	height: 0.2rem;
	margin: 0.8rem 0;
}

.ouSlider.x .ouSlider__handle {
	height: 100%;
}

.ouSlider.x .ouSlider__handle:after {
	top: -0.65rem;
	left: -1rem;
}

.ouSlider.x .ouSlider__value {
	top: 0;
	height: 100%;
}

.ouSlider.y {
	width: 0.2rem;
	margin: 0 0.8rem;
}

.ouSlider.y .ouSlider__handle {
	width: 100%;
}

.ouSlider.y .ouSlider__handle:after {
	top: -1rem;
	left: -0.65rem;
}

.ouSlider.y .ouSlider__value {
	left: 0;
	width: 100%;
}

/*.ouSlider.xy {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #2e8ece;
	border-radius: 0;
}

.ouSlider.xy .ouSlider__handle {
	position: absolute;
}

.ouSlider.xy .ouSlider__handle:after {
	position: relative;
	display: block;
	top: -10px;
	left: -10px;
	width: 20px;
	height: 20px;
	background-color: rgba(0, 0, 0, 0);
	border: 2px solid #fff;
	border-radius: 50%;
	content: '';
}*/
