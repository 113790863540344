/* Toggle */
.ouSelectDropdown--toggle {
    width: 100%;
    text-align: left !important;
	padding: 0.5rem 0.75rem !important;
	background-color: #ffffff;
    border: 1px solid rgba(0,0,0,.15);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.ouSelectDropdown--toggle:focus {
	outline: none;
	box-shadow: none;
}

.ouSelectDropdown--toggle::after {
    content: none !important;
}

.ouSelectDropdown--toggle--select {
    display: block;
    overflow: hidden;
	font-size: 0.8rem;
	line-height: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1rem;
}

.ouSelectDropdown--toggle--select--empty {
	font-family: 'Montserrat',Helvetica,Arial,sans-serif;
	font-size: 0.8rem;
	line-height: 1.25;
	color: #868e96;
}

.ouSelectDropdown--toggle--caret {
    float: right;
    color: #929292;
    line-height: inherit !important;
}

.ouSelectDropdown.show .ouSelectDropdown--toggle {
    border-color: #80bdff;
}

.ouSelectDropdown.disabled > .ouSelectDropdown--toggle {
	background-color: #eceeef;
	cursor: not-allowed;
}

/* Menu */
.ouSelectDropdown--menu {
    width: 100% !important;
    border-radius: 0 !important;
	border: 1px solid #adadad !important;
}

/* Items */
.ouSelectDropdown--item {
	position: relative;
    padding: 0.5rem 0.75rem !important;
    font-size: 0.8rem;
    line-height: 0.9rem;
	cursor: pointer;
}

.ouSelectDropdown--item.active,
.ouSelectDropdown--filter.active,
.ouSelectDropdown--item:active,
.ouSelectDropdown--filter:active {
    color: inherit !important;
    text-decoration: none;
    background-color: #f7f7f9 !important;
}

.ouSelectDropdown--item.disabled
.ouSelectDropdown--item--text,
.ouSelectDropdown--item.disabled
.ouSelectDropdown--item--check {
    color: #b1b1b1;
    cursor: disabled;
}

.ouSelectDropdown--item.disabled {
    cursor: not-allowed;
}

/* Check */
.ouSelectDropdown--item
.ouSelectDropdown--item--check {
    display: none;
}

.ouSelectDropdown.checks
.ouSelectDropdown--item
.ouSelectDropdown--item--text {
	user-select: none;
    cursor: inherit;
	display: block;
    margin-right: 1.25rem;
    white-space: normal;
	font-size: 0.8rem;
	line-height: 1rem;
}

.ouSelectDropdown.checks
.ouSelectDropdown--item.checked
.ouSelectDropdown--item--check {
	position: absolute;
    right: 0;
    display: block;
	font-size: 0.8rem;
	line-height: 1rem;
	top: calc(50% - 1.1rem);
	padding: 0.5rem 0.75rem;
}

/* Groups */
.ouSelectDropdown--group--title {
	user-select: none;
    display: block;
    padding: 0.5rem 0.75rem 0.25rem;
    line-height: 0.75rem;
    font-size: 0.75rem;
    cursor: default;
    color: #808080;
}

.ouSelectDropdown--group > .ouSelectDropdown--item {
    padding-left: 1.25rem !important;
}

/* Dropdowm Wrapper / Webkit Scroll */
.ouSelectDropdown--menu {
    width: 100%;
    height: 100%;
	padding: 10px 0;
    overflow-y: auto;
}

.ouSelectDropdown--menu::-webkit-scrollbar-track {
    background: #cccccc;
}

.ouSelectDropdown--menu::-webkit-scrollbar {
	width: 0.5rem;
}

.ouSelectDropdown--menu::-webkit-scrollbar-thumb {
	background-color: #a2a2a2;
}

/* Dropdown Message */
.ouSelectDropdown
.ouSelectDropdown--message {
	display: none;
}

.ouSelectDropdown.show
.ouSelectDropdown--message {
	user-select: none;
	cursor: default;
	/*#-#-#*/
	display: block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1000;
    min-height: 1.5rem;
    background-color: #ffffff;
    text-align: center;
    padding: 0.25rem 0.5rem;
    border: 1px solid #c9cbd0;
}

/* Show ID Class */
.ouSelectDropdown--item--text .selectId,
.ouSelectDropdown--item--text .selectContent {
	display: block;
    color: #b1b1b1;
    font-size: 0.75rem;
}

/* Filter */
.ouSelectDropdown:not(.show) .ouSelectDropdown--filter {
	display: none;
}

.ouSelectDropdown.filterable .ouSelectDropdown--menu {
	margin-top: 2.675rem;
}

.ouSelectDropdown--filter {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 9999;
    margin-top: 0.125rem;
	border: 1px solid #adadad !important;
	padding: 0 !important;
    font-size: 0.8rem;
    line-height: 1rem;
    cursor: default;
	display: block;
    white-space: nowrap;
    width: 100%;
}

.ouSelectDropdown--filter--input {
	padding: 0.25rem 0.5rem;
	border: 0;
    width: 100%;
	line-height: 2rem;
	background-color: #f3f3f3;
}

.ouSelectDropdown--filter--reset {
	padding: 0.25rem 0.65rem;
    line-height: 2rem !important;
    display: inline;
    position: absolute;
	float: right;
    right: 0;
    color: #7d7d7d;
	cursor: pointer;
}
