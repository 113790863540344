.ouBreadcrumb {
	padding: 0.75rem 1rem;
    list-style: none;
    background-color: #e9ecef;
	border-bottom: 0.2rem solid #58666e;
}

.ouBreadcrumb__Wrapper--inner > .ouBreadcrumb__Crumb:not(:first-child)::before {
	display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/";
}

.ouBreadcrumb__Crumb--inner {
    color: #58666e;
	user-select: none;
}

.ouBreadcrumb__Crumb--inner:hover {
    color: inherit;
	user-select: none;
}

.ouBreadcrumb__Crumb--inner.active {
    cursor: default;
}
