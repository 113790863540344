/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                              Global Styles                              //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

@import url('https://fonts.googleapis.com/css?family=Montserrat');

* {
    outline: 0!important;
}

[type=button], [type=reset], [type=submit], button, .nav-link {
    cursor: pointer;
}

body {
    font-family: 'Montserrat',Helvetica,Arial,sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.42857143;
    color: #58666e;
    background: #eceff5;
	font-weight: normal;
}

ul {
	list-style-type: none;
	overflow: hidden;
	text-align: left;
}

.btn {
    border-radius: 0;
}

label {
    margin: 0;
    display: block;
}

.form-control {
	font-size: 0.8rem;
}

.form-control, .input-group-addon {
    border-radius: 0;
    border: 1px solid rgba(0,0,0,.15);
}

.btn-primary {
    color: #fff;
    background-color: #e80c52;
    border-color: #666;
}

.smileys .btn-primary {
    background-color: #d1060c;
}

.btn-primary:hover, .btn-primary:focus {
	color: #fff;
	background-color: #d00a49;
	text-decoration: none;
}

.smileys .btn-primary:hover,
.smileys .btn-primary:focus {
	background-color: #9e0409;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #d63e6e;
    border-color: #666;
}

.smileys .btn-primary.disabled,
.smileys .btn-primary:disabled {
    background-color: #f92b42;
}

.btn-info {
    color: #999;
    background-color: #fff;
    border-color: #666;
}

.btn-info:hover, .btn-info:focus {
	color: #666;
	background-color: #e5e5e5;
	text-decoration: none;
}

.btn-info:disabled, .btn-info:disabled {
	color: #666;
	background-color: #e5e5e5;
	text-decoration: none;
}

.btn-danger {
    color: #fff;
    background-color: #a8630f;
    border-color: #666;
}

.smileys .btn-danger {
	background-color: #6c4233;
}

.btn-danger:hover, .btn-danger:focus {
	color: #fff;
	background-color: #97590d;
	text-decoration: none;
}

.smileys .btn-danger:hover, .smileys .btn-danger:focus {
	background-color: #583529;
}

.btn-danger.disabled, .btn-danger:disabled {
    background-color: #a16925;
    border-color: #666;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #666;
}

.panel-title .btn-group > button:not(:last-child) {
	margin-left: 0 !important;
    margin-right: 0.2rem !important;
}

.page-link {
	color: #999;
    background-color: #fff;
    border-color: #666;
}

.page-item.active .page-link {
	color: #fff;
    background-color: #999;
    border-color: #666;
}

.page-item.disabled .page-link {
    border-color: #666;
}

.page-link:hover, .page-link:focus {
	color: #999;
    background-color: #DDD;
    border-color: #666;
}

.ouStateButton .primary {
    color: #ffffff;
    background-color: #e80c52;
    border-color: #e80c52;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Navbar                                 //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.navbar {
	padding: 0;
    background-color: #ffffff;
    /*border-bottom: 1px solid #cfd8dc;*/
	border-bottom: 0 !important;
    height: 55px;
}

.navbar-light .navbar-nav .nav-link {
    font-weight: normal;
    font-size: 0.875rem;
	cursor: pointer;
}

header.navbar .navbar-toggler {
    padding-left: 0rem;
    position: absolute;
    top: 0;
    left: -50px;
    width: 70px;
    height: inherit;
    transition: left .3s ease-in-out;
}

@media (max-width: 777px) {
    header.navbar .navbar-toggler {
        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: inherit;
    }
}

header.navbar .navbar-brand {
    cursor: pointer;
}

header.navbar .navbar-brand-overlay {
	display: inline-block;
	background: rgba(255, 255, 255, 0.75);
	height: 100%;
	width: 100%;
	font-size: 15px;
	line-height: 37px;
	text-transform: uppercase;
}

/*
.navMenuToggle {
	display: block;
	position: absolute;
	right: 1rem;
	top: 1rem;
}

@media (min-width: 576px) {
	.navbar-toggleable .navbar-toggler {
		display: block;
	}

	.navMenuToggle {
		display: none;
	}
}
*/

@media (max-width: 575px) {
	.navbar-collapse {
	    position: absolute;
		margin-top: 55px;
		z-index: 99999;
	    left: 0;
	    top: 0;
		width: 100%;
	    background-color: #ffffff;
	    border-bottom: 1px solid #cfd8dc;
	}

	.navbar-collapse .nav-item {
	    padding: 0 1rem;
	}
}

header.navbar .ouTopButtons .nav-link:hover i,
header.navbar .ouTopButtons .nav-link:hover span {
    color: #e80c52;
}

.smileys header.navbar .ouTopButtons .nav-link:hover i,
.smileys header.navbar .ouTopButtons .nav-link:hover span {
    color: #d1060c;
}

header.navbar .nav-link {
    width: 34px;
    height: 34px;
    padding: 0;
    line-height: 32px;
    border: 1px solid #c0cadd;
    border-radius: 50em;
}

header.navbar .ouTopButtons {
    margin-right: 0px;
}

@media (min-width: 778px) {
    header.navbar .ouTopButtons {
        margin-right: 5px;
    }
}

header.navbar {
  position: relative;
  flex-direction: row;
  height: 55px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #cfd8dc;
}

header.navbar .navbar-brand {
    display: inline-block;
    width: 250px;
    height: 55px;
    padding: 0.5rem 1rem;
    margin-right: 0;
    background-color: #fff;
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px auto;
    border-bottom: 1px solid #cfd8dc;
    transition: margin .3s ease-in-out;
}

.smileys header.navbar .navbar-brand {
    background-image: url("../img/___/smileys/logo.png");
	background-size: 60px auto;
}

@media (max-width: 777px) {
    header.navbar .navbar-brand {
        margin-left: calc((100% - 250px)/2);
        border-bottom: none;
    }
}

header.navbar .navbar-nav {
    flex-direction: row;
    align-items: center;
}

header.navbar .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;
}

header.navbar .nav-item .nav-link, header.navbar .nav-item .dropdown-toggle {
      padding-top: 0;
      padding-bottom: 0;
}

header.navbar .nav-item .nav-link .badge, header.navbar .nav-item .dropdown-toggle .badge {
    position: absolute;
     top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: 0;
}

header.navbar .dropdown-menu {
    padding-bottom: 0;
    line-height: 1.5;
}

header.navbar .dropdown-item {
    min-width: 180px;
}

header.navbar .navbar-nav.ouTopButtons {
    position: absolute;
    right: 0;
    width: 110px;
    height: inherit;
}

@media (min-width: 778px) {
	.ouTopButtons {
        width: auto !important;
    }

    .ouTopButtons .nav-link {
        margin: 0 0.5rem;
    }
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Sidebar                                 //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

body {
    height: 100vh;
	font-size: 0.875rem;
    overflow: hidden;
}


/*
.navIcon {
	color: #869fb1;
	left: 1rem;
	margin-top: 1rem;
	display: inline-block;
	position: absolute;
}

.ouNavLink {
	color: #869fb1;
	display: inline-block;
	margin: 5px 0;
	margin-left: 2rem;
	padding: 0.5rem;
}

.ouNavLink:hover {
	color: white;
}

.ouNavLink.active{
	color: #ffffff;
}
*/

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Login                                   //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

/*.pageLogin {
    display: flex;
    min-height: 100vh;
    align-items: center !important;
    background: url('../img/ou-bg-pattern2.png') repeat right top;
}

.pageLogin .row {
    justify-content: center !important;
}

.pageLogin .container-fluid {
    height: 100vh;
}

.pageLogin .container-fluid .row {
    height: 100%;
    justify-content: center;
}

.pageLogin .input-group {
    margin-bottom: 1.5rem!important;
}

.pageLogin .input-group .input-group-addon i {
    color: #e80c52;
}

.pageLogin .card {
    padding: 1.5rem 1.5rem !important;
    text-align: center;
}

.pageLogin .card .card-img-top {
    height: 80px;
    padding: 1.25rem 1.25rem 0px;
}

.pageLogin .card button {
    font-size: 0.825rem;
}

.pageLogin .card img.card-img-top {
    width: 100%;
}

@media (max-width: 1199px) {
    .pageLogin .card.hidden-md-down .card-block {
        padding: 0;
    }
}*/

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                  Modal                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.modal-header .modal-title {
	width: 100%;
}

.modal-header .modal-title .btn-sm {
	border-radius: 0;
	border: 1px solid #666;
}

.modal-header .modal-title span {
	float: left;
    line-height: 34px;
    margin: 0;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                  Panel                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.panel {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #fff;
    border: solid 1px #d8e2e7;
    margin: 15px 0 20px;
}

.panel .panel-heading {
    padding: 12px 18px 12px;
    border-bottom: solid 1px #d8e2e7;
}

.panel .panel-heading .panel-title h4 {
	margin-bottom: 1px;
	line-height: 29px;
}

.panel .panel-heading .panel-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    padding: 0;
    margin-top: 5px;
}

.panel .panel-heading .panel-title h4 {
    display: inline-block;
}

.panel .panel-heading .btn-group {
    float: right;
    margin-top: -6px;
}

@media (max-width: 410px) {
    .panel .panel-heading .btn-group {
		float: none;
		display: block;
    	margin: 5px 0 0 -3px;
    }
}

.panel-body.wrapper {
	padding: 1rem;
}

.panel-body .badge {
    cursor: default;
    vertical-align: text-top;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Table                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.table {
	margin-bottom: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
}

.table th, .table td {
    padding: 0.5rem 0.5rem 0.45rem;
    font-size: 0.75rem;
    line-height: 1.8rem;
    border-top: none;
}

@media (max-width: 1199px) {
    .table th, .table td {
        line-height: 1rem;
    }
}

.table th {
    background: #f6f6f6;
    border-bottom: solid 1px #858585;
    padding: 0;
    font-weight: 700;
    color: #9a9a9a;
    font-size: 0.8rem;
}

.table th>div {
    position: relative;
    padding: 4px 10px;
}

.table th>div:before {
    content: '';
    display: block;
    width: 1px;
    height: 85%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(top,rgba(216,226,231,0) 0,#d8e2e7 75%,#d8e2e7 100%);
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(216,226,231,0)),color-stop(75%,#d8e2e7),to(#d8e2e7));
    background: linear-gradient(to bottom,rgba(216,226,231,0) 0,#d8e2e7 75%,#d8e2e7 100%);
}

.table th:first-child>div:before {
    background: none;
}

.table td {
    padding-bottom: 8px;
}

.table-big .table td {
    height: 50px;
}

.table-big .table td, .table-big .table th {
    vertical-align: middle;
    border-top-color: #d8e2e7;
    padding: 11px 10px 10px;
}

.table .optionButtons {
    text-align: right;
}

.table .optionButtons button {
    min-width: 32px;
    margin-left: 2px;
}

.table .optionButtons button span {
    font-size: 0.75rem;
}

@media (max-width: 1199px) {
    .table td.optionButtons {
        min-width: 155px;
    }
}

/*tr>td, tr>td>span {
    line-height: 1.75rem !important;
}*/

tr td {
    transition: background-color 0.2s linear;
}

/* tr td:last-child {
    padding-right: 18px;
} */

/*tr:hover>td {
    background-color: #eceff5;
    color: #000;
}*/

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                               Progress                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.progress-with-amount {
    position: relative;
    padding-right: 40px;
}

.progress.progress-no-margin {
    margin: 0;
}

.progress[value] {
    color: #00a8ff;
}

.progress-with-amount .progress-with-amount-number {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 16px;
    color: #919fa9;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .25rem;
    margin-left: .25rem;
    vertical-align: middle;
    content: "";
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: relative;
    top: -1px;
}

.caret.caret-up {
    border-top: none;
    border-bottom: solid 5px;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                  Card                                   //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.card {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #cfd8dc;
}

.card i {
    color: #fff;
}

@media (min-width: 576px) {
    .card-group .card {
        flex: 1 0 0;
    }
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Helper                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.font-2xl {
    font-size: 1.5rem!important;
}

.p-a-1 {
    padding: 1rem!important;
}

.p-b-1 {
	padding-bottom: 1rem !important;
}

.m-r-1, .m-x-1 {
    margin-right: 1rem!important;
}

.m-b-1 {
	margin-bottom: 1rem !important;
}

.text-info {
    color: #63c2de!important;
}

.m-b-0, .m-y-0 {
    margin-bottom: 0!important;
}

.m-t-h, .m-y-h {
    margin-top: .5rem!important;
}

.font-xs {
    font-size: .75rem!important;
}

.font-11 {
    font-size: .6875rem;
}

.color-blue-grey-lighter {
    color: #919fa9!important;
}

.font-weight-bold {
    font-weight: 700;
}

.text-uppercase {
    text-transform: uppercase!important;
}

.color-red {
    color: #fa424a!important;
}

.color-green {
    color: #46c35f!important;
}

.mw-40 {
    min-width: 40px;
}

.mw-60 {
    min-width: 60px;
}

.mw-80 {
    min-width: 80px;
}

.mw-100 {
    min-width: 100px;
}

.mw-120 {
    min-width: 120px;
}

.mw-140 {
    min-width: 140px;
}

.mw-160 {
    min-width: 160px;
}

.mw-180 {
    min-width: 180px;
}

.mw-200 {
    min-width: 200px;
}

.mw-220 {
    min-width: 220px;
}

.mw-240 {
    min-width: 240px;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                  Form                                   //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                               Pagination                                //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

@media (max-width: 777px) {
	.ouPagination .ouPaginationLink {
	    font-size: 14px;
	    padding: 0.35rem 0.3rem;
		margin-bottom: 2rem;
	}

	.ouPagination {
	    margin-bottom: 1.5rem;
	}
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Orders                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

tr.payment,
tr.payment.default {
    color: #ffffff !important;
    background-color: #637078 !important;
}

tr.payment.paypal {
    color: #ffffff !important;
    background-color: #009cde !important;
}

.panel-product .panel-heading {
    padding: 0.6rem 1rem 0.5rem !important;
}

.panel-product .panel-heading,
.panel-product .panel-heading * {
    margin: 0 !important;
}

.panel-product .panel-title * {
    font-size: 1rem;
    line-height: 1rem;
}

.panel-product .panel-heading {
    background-color: #828282;
    color: #ffffff;
}

tr.cart-total {
    background-color: #464646 !important;
    color: #ffffff;
}

.txt-r {
	float: right !important;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                                 Custom                                  //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.ouHero {
    color: #ffffff;
    background: #444444;
    padding: 1.5rem 1rem;
    font-size: 1.25rem;
	line-height: 1.25rem;
    font-weight: 500;
}

span.loadingError {
	padding-left: 0.5rem;
}

.btn.reload {
    display: block;
    margin: 0 auto;
    margin-top: 0.25rem;
}

.form-control.sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25;
    border-radius: 0;
}

.inputButtonAddon {
	padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25;
    color: #ffffff;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.inputButtonAddon.sm {
	color: #ffffff;
    border-width: 1px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25;
    border-radius: 0;
}

@media (max-width: 777px) {
    .hidden-no-dock {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 1200px;
    }
}

@media (max-width: 1305px) {
	.table-responsive {
	    display: block;
	    width: 100%;
	    overflow-x: auto;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

.modal-dialog h4.modal-title {
	font-size: 1.2rem;
}

.panel .panel-title h4 {
	font-size: 1.2rem;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                               Pils                      			     //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.nav.nav-pills {
	margin: 18px 0 0;
}
.nav.nav-pills button {
	margin: 0 3px 3px 0;
	border: 1px solid #b0bec5;
}

.nav-pills .nav-link.active, .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #e80c52;
	border-color: #666;
}

.smileys .nav-pills .nav-link.active,
.smileys .show > .nav-pills .nav-link {
    background-color: #d1060c;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
    background-color: #e5e5e5;
}

.nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
    color: #fff !important;
    background-color: #d00a49;
}

.smileys .nav-pills .nav-link.active:hover,
.smileys .nav-pills .nav-link.active:focus {
    background-color: #9e0409;
}

/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/
/*//                               Breadcrumb                                //*/
/*//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//*/

.breadcrumb {
	border-radius: unset;
	border-bottom: 1px solid #333;
}

.ouBreadcrumb {
	margin-bottom: 0;
}

.ouBreadcrumbLink {
    cursor: pointer;
}

.ouEnduserContacts > div {
	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #ececec;
}

@media (min-width: 1200px) {
	.ouEnduserContacts > div {
		border-width: 0px 0px 0px 0px;
	}
}

.ouEnduserContacts > div:first-child {
	border-right-width: 1px;
}

.ouEnduserContacts > div:last-child {
	border-left-width: 1px;
}

.ouEnduserContacts .ouEnduserContacts--wrapper {
	padding: 1rem;
}
