/* Basics */
.ouAlert {
    position: absolute;
    z-index: 999999;
	width: 15rem;
	height: 100vh;
	overflow: hidden;
	pointer-events: none;
}

.ouPage > .ouAlert {
	height: 100%;
    max-height: calc(100vh - 56px);
}

@media (min-width: 778px) {
	.ouAlert {
		width: 25rem;
	}
}

/* Top */
.ouAlert.t-l {
    top: 0;
    left: 0;
}

.ouAlert.t-c {
	top: 0;
    left: 0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
}

.ouAlert.t-r {
    top: 0;
    right: 0;
}

/* Center */
.ouAlert.c-l {
    left: 0;
	top: 0;
	bottom: 0;
	margin-top: auto !important;
    margin-bottom: auto !important;
}

.ouAlert.c-c {
	top: 0;
    left: 0;
    right: 0;
	bottom: 0;
    margin: auto !important;
}

.ouAlert.c-r {
	right: 0;
	top: 0;
	bottom: 0;
	margin-top: auto !important;
	margin-bottom: auto !important;
}

/* Bottom */
.ouAlert.b-l {
    bottom: 0;
    left: 0;
}

.ouAlert.b-c {
	bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
}

.ouAlert.b-r {
    bottom: 0;
    right: 0;
}

/* Alert Messages */
.ouAlert__message {
    position: relative;
	min-width: 100%;
	max-width: 100%;
    padding: .75rem 3rem 0.75rem 1.25rem;
	margin-bottom: 0.25rem;
	pointer-events: all;
}

.ouAlert__message--text {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
    cursor: default;
}

/* Transition */
.ouAlert__message.scale-enter {
    transform: scale(0);
}

.ouAlert__message.scale-enter-active {
	transform: scale(1);
    transition: all 250ms cubic-bezier(0, 0, 0.5, 1.5);
}

.ouAlert__message.scale-leave {
    transform: scale(1);
}

.ouAlert__message.scale-leave.scale-leave-active {
	transform: scale(0);
	transition: all 250ms ease-in-out;
}

/* Close Button*/
.ouAlert__message--close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.25rem;
    line-height: 1.2rem;
    padding: 0.75rem 1rem 0 0;
    text-align: center;
    cursor: pointer;
}

/* Styles */
.ouAlert__message,
.ouAlert__message--default {
	background-color: #ffffff;
    border: 1px solid #cfd8dc;
}

.ouAlert__message--error {
	background-color: #d9534f;
    border: 1px solid #ca2a26;
	color: #ffffff;
}
